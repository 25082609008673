import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';

import { attachmentURL, dataURL, issueURL } from '@core/http/endpoints';

import { GogisColumn, ModelNormalize } from '../models';
import {
  Field,
  Fieldset,
  GogisAction,
  ModelOptionsResponse,
  TableField,
} from '../models/response-model-options';

/**
 * Service to get Options.
 */
@Injectable({
  providedIn: 'root',
})
export class OptionsService {
  constructor(private http: HttpClient) {}

  getTableOptions(
    contentType: number,
    url?: string,
  ): Observable<ModelNormalize> {
    let optionUrl = `${dataURL}/${contentType}`;
    if (url) {
      optionUrl = url;
    }
    return this.http.options<ModelOptionsResponse>(optionUrl).pipe(
      map((optionsResponse) => {
        const optionsModel: ModelNormalize = {};

        const exports = [{ url: null, label: 'MAP.MAP_PANEL.EXPORT_AS' }];
        optionsResponse.actions.exports.forEach((expor) => {
          exports.push(expor);
        });

        if (optionsResponse.model_reports) {
          const reports = [];
          const reportsKey = Object.keys(optionsResponse.model_reports);
          for (const report of reportsKey) {
            reports.push(optionsResponse.model_reports[report]);
          }

          optionsModel.reports = reports;
        }
        const processess: GogisAction[] = [];

        if (
          optionsResponse.actions.processess ||
          optionsResponse.actions.async_processess
        ) {
          optionsResponse.actions.processess?.forEach((process) => {
            processess.push(process);
          });

          optionsResponse.actions.async_processess?.forEach((process) => {
            processess.push(process);
          });

          optionsModel.actions = { processess };
        }

        optionsModel.actions = { exports, processess };
        optionsModel.hasAttachment = !!optionsResponse.inlines.find(
          (inline) => inline.name === 'attachments',
        );
        optionsModel.hasIssue = !!optionsResponse.inlines.find(
          (inline) => inline.name === 'issues',
        );

        optionsModel.fieldsets = this.markFieldsetWithGeom(
          optionsResponse.fieldsets,
        );
        optionsModel.inlines = optionsResponse.inlines.length
          ? optionsResponse.inlines
          : null;
        optionsModel.columns = this.generateColumns(
          optionsResponse.table_fields,
          optionsResponse.fieldsets,
        );
        optionsModel.django_content_type = contentType;

        return optionsModel;
      }),
    );
  }

  getAttachmentOptions(): Observable<ModelOptionsResponse> {
    return this.http.options<ModelOptionsResponse>(attachmentURL);
  }

  getIssueOptions(): Observable<ModelOptionsResponse> {
    return this.http.options<ModelOptionsResponse>(issueURL);
  }

  /** Create the columns for ngx-datatable */
  // eslint-disable-next-line max-lines-per-function
  private generateColumns(
    tableField: TableField[],
    fieldsets: Fieldset[],
  ): GogisColumn[] {
    const columns: GogisColumn[] = [];

    tableField.forEach((column) => {
      if (column.name === 'the_geom') {
        return;
      }
      let matchField: Field;
      fieldsets.forEach((fieldset) => {
        matchField =
          fieldset.fields.find(
            (field) => field.name === column.name.replace('_displayname', ''),
          ) || matchField;
      });

      let cellDataType;
      let filterType: string | boolean =
        column.filter_conditions === null ? false : true;
      let filterParams: any = {};
      let valueFormatter = null;
      if (column.filter_conditions?.length > 0) {
        filterParams = {
          //Set to 1 because backend only supports 1 condition
          maxNumConditions: 1,

          buttons: ['reset'],
        };
        if (matchField) {
          switch (matchField.type) {
            case 'integer':
              filterType = 'agNumberColumnFilter';
              break;
            case 'decimal':
              filterType = 'agNumberColumnFilter';
              break;
            case 'boolean':
              cellDataType = 'boolean';
              filterType = 'agBooleanColumnFilter';
              // filterParams = {
              //   ...filterParams,
              //   filterOptions: ['true', 'false', 'blank', 'notBlank'],
              // };
              break;
            case 'date':
              filterType = 'agDateColumnFilter';
              valueFormatter = this.dateFormatter;
              break;
            case 'datetime':
              valueFormatter = this.dateFormatter;
              break;
            default:
              filterType = 'agTextColumnFilter';
          }
        }
      }

      columns.push({
        field: column.name,
        flex: 1,
        minWidth: 100,
        cellDataType,
        filter: filterType,
        filterParams,
        headerName: column.label || column.name,
        sortable: true,
        pinned: column.name === 'object_title' ? 'left' : null,
        // type: matchField?.type,
        valueFormatter:
          column.name === 'last_updated' || column.name === 'created'
            ? this.dateFormatter
            : valueFormatter,
      });
    });

    return columns;
  }

  private dateFormatter(params: any): string {
    if (!params.value) {
      return '';
    }

    const date = new Date(params.value);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  }

  private markFieldsetWithGeom(fieldsets: Fieldset[]): Fieldset[] {
    return fieldsets.map((fieldset) => {
      if (
        fieldset.fields.filter((field) => field.type === 'geometry').length > 0
      ) {
        fieldset = { ...fieldset, type: 'geometry' };
      }
      return fieldset;
    });
  }
}
